import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import { Container } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import ArticleCard from "../components/ArticleCard"

const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx
  return (
    <Layout>
      <Seo title={"Blog"} />

      <Section textAlign={"left"}>
        <Container maxWidth={"md"}>

          <Typography variant={"h1"}>Blog</Typography>

          <div>

          </div>
          {posts.map(({ node: post }) => (
            <ArticleCard key={post.id} post={post} />
          ))}
        </Container>
      </Section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query blogIndex {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            keywords
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 880) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default BlogIndex
